const messages = {
  'menu.home': '首页',
  'menu.documentmap': '文档地图',
  'menu.documenttree': '文档结构树',
  'menu.myrelated': '与我相关',
  'menu.mycreated': '我发起的',
  'menu.mytask': '我的任务',
  'menu.sharewithme': '分享给我',
  'menu.myshare': '我分享的',
  'menu.myfavorites': '我的收藏',
  'menu.documentquery': '文档检索',
  'menu.documentdashboard': '文档报表',
  'menu.systemmanagement': '系统管理',
  'menu.processmanagement': '审批流程管理',
  'menu.masterdatamanagement': '主数据管理',
  'menu.recyclebin': '回收站',
  'menu.announcementmanagement': '公告栏管理',
  'menu.usermanagement': '权限申请',
  'menu.workflowmanagement': '审批流程设置',
  'menu.getsupport': '帮助',
  'menu.publisheddocument': '更新文档',
  'menu.messagelist': '留言列表',

  'menu.documentmanagement': '文档管理',
  'menu.searchdocumentnumber': '文档编号查询',
  'menu.allversions': '文档发起记录',

  'menu.masterdatasetting': '主数据管理',
  'menu.tagmanagement': '文档标签管理',
  'menu.treenode': '文档主数据',
  'menu.systemsetting': '系统管理',
  'menu.processsetting': '流程配置',
  'menu.emailtemplatesetting': '添加邮件模板',
  'menu.emailtemplate': '邮件模板',

  'menu.adjustapproversetting': '修改审批人',
  'menu.documentstructuretree': '文档结构树',
  'menu.documentcreate': '创建文档',
  'menu.documentedit': '编辑草稿',
  'menu.documentupdate': '文档升版',
  'menu.initiateddocument': '我发起的文档',
  'menu.approveddocument': '我审批的文档',
  'menu.subscriptiondepartment': '订阅部门',
  'menu.documentmapsetting': '文档地图设置',
};
export default messages;
