import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLoginInfo } from '@/api/account';
import {
  authorizeCode,
  refreshToken,
  removeRefreshToken,
  removeAuthToken,
} from '@/api/auth';

// constants
export const AUTH_STATUS = {
  NOTLOGGEDIN: 'notloggedin',
  LOGGINGIN: 'loggingin',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

const initialState = {
  status: AUTH_STATUS.NOTLOGGEDIN,
  token: false,
  user: {},
  topmenus: [],
  menus: [],
  permissions: [],
  actions: [],
  roles: [],
  defaultHomePage: '/home',
  instanceCode: 'L001',
  error: '',
  errorcode: 0,
};

// async thunks
export const fetchToken = createAsyncThunk(
  'auth/fetchToken',
  async (userArgs, { rejectWithValue }) => {
    try {
      let error = true;
      if (userArgs) {
        if (userArgs.code) {
          const response = await authorizeCode(
            userArgs.code,
            userArgs.redirect_uri
          );
          error = false;
          return response;
        } else if (userArgs.refresh_token) {
          const response = await refreshToken(userArgs.refresh_token);
          error = false;
          return response;
        }
        if (error) {
          return rejectWithValue({
            status: 400,
            message: 'userArgs is required',
          });
        }
      }
    } catch {
      return rejectWithValue();
    }
  }
);
export const fetchUser = createAsyncThunk('auth/fetchUser', getLoginInfo);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  removeRefreshToken();
  removeAuthToken();
});

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [fetchToken.pending]: (state, action) => {
      state.status = AUTH_STATUS.LOGGINGIN;
    },
    [fetchToken.fulfilled]: (state, action) => {
      state.token = true;
    },
    [fetchToken.rejected]: (state, action) => {
      state.status = AUTH_STATUS.FAILED;
    },
    [fetchUser.fulfilled]: (state, action) => {
      const {
        topMenus,
        menus,
        routers,
        actions,
        roleList,
        defaultHomePage,
        instanceCode,
        ...user
      } = action.payload;
      state.user = user;
      state.topmenus = topMenus;
      state.menus = menus;
      state.permissions = routers;
      state.actions = actions;
      state.roles = roleList;
      state.defaultHomePage = defaultHomePage;
      state.status = AUTH_STATUS.SUCCEEDED;
      state.instanceCode = instanceCode;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = AUTH_STATUS.FAILED;
    },
    // [signOut.fulfilled]: (state, action) => {
    //   state.status = AUTH_STATUS.NOTLOGGEDIN;
    //   state.token = false;
    //   state.user = {};
    //   state.menus = [];
    //   state.permissions = [];
    // },
  },
});

// reducer
export default authSlice.reducer;

// selector
export const selectStatus = state => state.auth.status;
export const selectUser = state => state.auth.user || '';
export const selectUserId = state => state.auth.user.id;
export const selectUserType = state => state.auth.user.userType;
export const selectUserEmail = state => state.auth.user.email;
export const selectName = state => state.auth.user.name || '';
export const selectPermission = state => state.auth.permissions;
export const selectAction = state => state.auth.actions;
export const selectTopMenus = state => state.auth.topmenus;
export const selectMenus = state => state.auth.menus;
export const selectRoles = state => state.auth.roles;
export const selectDefaultHomePage = state => state.auth.defaultHomePage;
export const selectInstanceCode = state => state.auth.instanceCode;
