const messages = {
  'menu.home': 'Home',
  'menu.documentmap': 'Library Map',
  'menu.documenttree': 'Document Hierarchy',
  'menu.myrelated': 'My Related',
  'menu.mycreated': 'My Created',
  'menu.mytask': 'My Task',
  'menu.sharewithme': 'Shared with me',
  'menu.myshare': 'Shared by me',
  'menu.myfavorites': 'My Favorites',
  'menu.documentquery': 'Search',
  'menu.documentdashboard': 'Dashboard',
  'menu.systemmanagement': 'System Management',
  'menu.processmanagement': 'Approval Worfklow Management',
  'menu.masterdatamanagement': 'Master Data Maintenance',
  'menu.recyclebin': 'Recycle Bin',
  'menu.announcementmanagement': 'Announcment Management',
  'menu.usermanagement': 'Permission Request',
  'menu.workflowmanagement': 'Approval Worfklow Settings',
  'menu.getsupport': 'Help',
  'menu.publisheddocument': 'Update Documents',
  'menu.messagelist': 'Comments to me',
  'menu.documentmanagement': 'Document Management',
  'menu.masterdatasetting': 'Master Data Maintenance',
  'menu.tagmanagement': 'Document Tags Management',
  'menu.treenode': 'Document Master Data',
  'menu.systemsetting': 'System Management',
  'menu.processsetting': 'Worfklow Setting',
  'menu.emailtemplatesetting': 'Add Email Template',
  'menu.emailtemplate': 'Email Template',
  'menu.adjustapproversetting': 'Update Approvers',
  'menu.documentstructuretree': 'Document Hierarchy',
  'menu.documentcreate': 'New Document',
  'menu.documentedit': 'Edit Draft',
  'menu.documentupdate': 'Upgrade Document',
  'menu.documentmapsetting': 'Document Map Setting',
};
export default messages;
