const messages = {
  'ph.username': 'User Name',
  'ph.password': 'Password',
  'ph.validcode': 'Verified Code',
  'ph.pleseSelect': 'Please select...',
  'ph.pleseInput': 'Please Input...',
  'ph.pleseInputEmail': 'Please input Email...',
  'ph.issubmint': 'Are you sure to submit?',
  'ph.issave': 'Are you sure to save?',
  'ph.pleseInputRemark': 'Please input comments',
  'ph.document.edit': 'Are you sure to update the document?',
};
export default messages;
