import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Icon } from '@ant-design/compatible';
import { ReactComponent as CreateDocuments } from '@/assets/icons/CreateDocuments.svg';
import { ReactComponent as DocumentRetrieval } from '@/assets/icons/DocumentRetrieval.svg';
import { ReactComponent as DocumentStructureTree } from '@/assets/icons/DocumentStructureTree.svg';

// import { ReactComponent as DocumentMap } from '@/assets/icons/DocumentMap.svg';
import { ReactComponent as documentdashboard } from '@/assets/icons/documentdashboard.svg';
import { ReactComponent as getsupport } from '@/assets/icons/getsupport.svg';
import { ReactComponent as myrelated } from '@/assets/icons/myrelated.svg';
import { ReactComponent as systemmanagement } from '@/assets/icons/systemmanagement.svg';
import { ReactComponent as UpdateDocument } from '@/assets/icons/UpdateDocument.svg';
import { ReactComponent as approve } from '@/assets/icons/approve.svg';
import { ReactComponent as DocumentMap } from '@/assets/icons/DocumentMap.svg';
import { ReactComponent as Pdf } from '@/assets/icons/pdf.svg';
import { ReactComponent as DefaultDocument } from '@/assets/icons/default.svg';
import { ReactComponent as Collection } from '@/assets/icons/Collection.svg';

const Icons = props => {
  useEffect(() => {
    if (props.id) {
      let svgDom = document.getElementById(props.id)?.firstChild;
      if (svgDom) {
        var childElements = svgDom.childNodes[3].childNodes;
        svgDom.childNodes[0].innerHTML = '';
        svgDom.childNodes[1].innerHTML = '';
        // 遍历子元素并输出路径
        childElements.forEach(function (childElement) {
          childElement.classList.remove('cls-2');
          childElement.setAttribute('style', 'fill: currentColor !important;');
        });
      }
    }
  });
  return <Icon id={props.id} component={props.component} {...props} />;
};

const CustomIcon = props => {
  let svgDom = document.getElementById(props.id)?.firstChild;
  if (svgDom) {
    return (
      <span
        role="img"
        id={props.id}
        class={'anticon ' + props.className}
        style={props?.style}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class=""
          dangerouslySetInnerHTML={{ __html: svgDom.innerHTML }}
        />
      </span>
    );
  }
  return <Icons component={props.component} {...props} />;
};
//创建文档
export const CreateDocumentsIcon = props => {
  return <CustomIcon component={CreateDocuments} {...props} />;
};

//我的收藏
export const CollectionIcon = props => (
  <CustomIcon component={Collection} {...props} />
);

//文档检索
export const DocumentRetrievalIcon = props => (
  <CustomIcon component={DocumentRetrieval} {...props} />
);

//文档结构
export const DocumentStructureTreeIcon = props => (
  <CustomIcon component={DocumentStructureTree} {...props} />
);

//文档报表
export const DocumentdashboardIcon = props => (
  <CustomIcon component={documentdashboard} {...props} />
);

//帮助文档
export const GetsupportIcon = props => (
  <CustomIcon component={getsupport} {...props} />
);

//我相关的
export const MyrelatedIcon = props => (
  <CustomIcon component={myrelated} {...props} />
);

//系统管理
export const SystemmanagementIcon = props => (
  <CustomIcon component={systemmanagement} {...props} />
);

//更新文档
export const UpdateDocumentIcon = props => (
  <CustomIcon component={UpdateDocument} {...props} />
);

//审批
export const ApproveIcon = props => (
  <CustomIcon component={approve} {...props} />
);

//文档地图
export const DocumentMapIcon = props => (
  <CustomIcon component={DocumentMap} {...props} />
);

//默认文档
export const DefaultDocumentIcon = props => (
  <CustomIcon component={DefaultDocument} {...props} />
);

//pdf
export const PdfIcon = props => <CustomIcon component={Pdf} {...props} />;
