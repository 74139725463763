export const debugEnable = process.env.NODE_ENV === 'development';

export function showTableTotal(total, range) {
  return `${range[0]}-${range[1]} of ${total} items`;
}
export function showFormat(drugs, number) {
  let count = 6;
  if (number) {
    count = number;
  }
  if (drugs) {
    let stringdrugs = drugs.toString();
    if (stringdrugs.length > count) {
      stringdrugs = stringdrugs.substr(0, count) + '...';
    }
    return stringdrugs;
  }
  return drugs;
}
export function stringSorter(a, b, index) {
  let stringA = a[index];
  let stringB = b[index];
  if (stringA && stringB) {
    stringA = stringA.toUpperCase();
    stringB = stringB.toUpperCase();
  }
  if (stringA < stringB) return -1;
  if (stringA > stringB) return 1;
  return 0;
}

export function numberSorter(a, b, index) {
  return a[index] - b[index];
}

/**
 * 判断是否是中文
 * @param {*} str
 * @returns
 */
const isChinese = str => {
  let lst = /[u00-uFF]/;
  return !lst.test(str);
};

/**
 * 中英混合技术字符长度
 * @param {*} str
 */
export const strLen = str => {
  let strLength = 0;
  for (let i = 0; i < str.length; ++i) {
    if (isChinese(str.charAt(i)) === true) {
      strLength = strLength + 2; //中文算2个字符
    } else {
      strLength = strLength + 1;
    }
  }

  return strLength;
};

/**
 * 是否包含中文(不包含标点符号)
 * @param {*} str
 */
export const isIncludeChinese = str => {
  // let lst = /^[\u4e00-\u9fa5]+$/;
  let lst = /[\u4e00-\u9fa5]/;
  var chinesePunctuationRegex =
    /[\u3000-\u303F\uFF00-\uFFEF\u2000-\u206F\u2E80-\u2EFF\u2F00-\u2FDF\u2FF0-\u2FFF\u3100-\u312F\u3200-\u32FF\u3300-\u33FF\u3400-\u4DBF\u4E00-\u9FFF\uA000-\uA48F\uA490-\uA4CF\uFE10-\uFE1F\uFE30-\uFE4F\uFF00-\uFFEF]/;
  let data = str.replaceAll(' ', '');
  for (let i = 0; i < data.length; ++i) {
    if (lst.test(data.charAt(i)) === true) {
      return true;
    }
    if (chinesePunctuationRegex.test(data.charAt(i))) {
      return true;
    }
  }
  return false;
};
