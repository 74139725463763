import docCookies from '@/utils/cookie';

const LOCALE_KEY = 'ABB_LANG';

export function getLocale() {
  let locale =
    window.localStorage?.getItem(LOCALE_KEY) || docCookies.getItem(LOCALE_KEY);
  if (!locale) {
    // locale = (navigator.language || navigator.browserLanguage).toLowerCase();
    locale = 'zh-CN';
  }
  return locale.includes('zh') ? 'zh-CN' : 'en-US';
  // return 'zh-CN';
}

export function setLocale(locale) {
  window.localStorage?.setItem(LOCALE_KEY, locale);
  const endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 1);
  docCookies.setItem(LOCALE_KEY, locale, endDate, '/');
  window.location.reload();
}

export function getLocales() {
  return [
    { key: 'zh-CN', value: '中文' },
    { key: 'en-US', value: 'English' },
  ];
}
