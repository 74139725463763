import http from './http';

export function getLoginInfo() {
  return http.get('/Account/GetLoginUserInfo');
}

export function getUserSelectList(data) {
  return http.post('/Account/GetUserSelectList', data);
}
export function GetEmployeeDataList(data) {
  return http.get('/Account/GetEmployeeDataList?keyWord=' + data);
}
