import error from './zh-CN/error';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import button from './zh-CN/button';
import placeholder from './zh-CN/placeholder';
import validmessage from './zh-CN/validmessage';
import tipmessage from './zh-CN/tipmessage';
import home from './zh-CN/home';

const zhCN = {
  'common.loading': '正在处理中...',
  'common.delconfirm': '删除确认',
  'common.delconfirmname': '您确认要删除{name}吗？',
  'common.remark': '备注',
  'layout.appname': '文件管理系统',
  'layout.lang': '多语言',
  'layout.signout': '退出',
  'modal.title': '系统消息',
  'layout.announcement': '公告',
  'system.notice': '通知公告',
  'system.draftbox': '我的草稿箱',
  ...error,
  ...menu,
  ...pages,
  ...button,
  ...placeholder,
  ...validmessage,
  ...tipmessage,
  ...home,
};
export default zhCN;
