const messages = {
  'b.login': '登录',
  'b.abbemaillogin': 'ABB邮箱登录',
  'b.search': '搜索',
  'b.save': '保存',
  'b.submit': '提交',
  'b.cancel': '取消',
  'b.upload': '上传',
  'b.uploadpictures': '上传图片',
  'b.additem': '添加',
  'b.moveto': '移动至',
  'b.addto': '添加至',
  'b.add': '新建',
  'b.edit': '编辑',
  'b.delete': '删除',
  'b.restoredocument': '恢复文档',
  'b.yes': '是',
  'b.no': '否',
  'b.exportall': '导出全部',
  'b.export': '导出',
  'b.import': '导入',
  'b.detail': '详情',
  'b.workflowchart': '流程图',
  'b.approve': '同意',
  'b.confirmpublish': '确认发布',
  'b.publish': '发布',
  'b.reject': '拒绝',
  'b.return': '退回',
  'b.approval': '审批',
  'b.receipt': '确认收货',
  'b.confirm': '确认',
  'b.determine': '确定',
  'b.check': '校验',
  'b.sparrow': 'Sparrow',
  'b.download': '下载',
  'b.more': '更多',
  'b.reset': '重置',
  'b.viewattachments': '查看附件',
  'b.adddata': '添加数据',
  'b.back': '返回',
  'b.collapse': '收起',
  'b.expand': '展开',
  'b.preview': '预览',
  'b.historyversion': '历史版本',
  'b.leavemessage': '留言',
  'b.subscription': '订阅',
  'b.unsubscription': '取消订阅',
  'b.openstructuretree': '查看结构树',
  'b.share': '分享',
  'b.collect': '收藏',
  'b.cancelfavorite': '取消收藏',
  'b.favoritecategory': '收藏夹',
  'b.favoritemanage': '管理收藏夹',
  'b.publication': '发表',
  'b.addregion': '添加区域',
  'b.setregion': '设置区域',
  'b.deleteregion': '删除区域',
  'b.viewregion': '查看区域',
  'b.newannouncement': '新增公告',
  'b.editannouncement': '编辑公告',
  'b.downloadfile': '下载文件',
  'b.deletefile': '删除文件',
  'b.documentcopy': '复制文档',
};
export default messages;
