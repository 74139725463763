const messages = {
  'm.delete.succress': 'Delete Successfully.',
  'm.delete.documents': 'Are you sure to delete the selected documents?',
  'm.delete.favorite': 'Are you sure to delete the selected favorites?',
  'm.delete.Message': 'Are you sure to delete the comments?',
  'm.cancelfavorite.succress': 'Cancel favorited documents sucessfully.',
  'm.cancelfavorite.documents':
    'Are you sure to cancel the favorited documents?',
  'm.share.succress': 'Share sucessfully',
  'm.share.documents': 'Are you sure to share the selected documents?',
  'm.favorite.succress': 'Add to favorites sucessfully.',
  'm.favorite.documents': 'Are you sure to cancel the favorite documents?',
  'm.restore.documents': 'Are you sure to restore the documents?',
  'm.restore.succress': 'Restore sucessfully',
  'm.delete.leavemessage': 'Are you sure to delete the seleted comments?',
  'm.delete.announcement': 'Are you sure to delete the announcement?',
  'e.editing.documents':
    'The document will be locked once do update, are you sure to update?',
  'p.delete.nopermissions': 'Access Denied',
  'p.delete.approval': 'Cannot delete the nondrafted documents.',
  'b.uploadpictures.error': 'Please upload the picture.',
  'm.move.succress': 'Success.',
  'm.move.documents': 'Are you sure to move the selected documents?',
  'm.add.documents': 'Are you sure to add the selected documents to new node?',
  'b.region.error': 'Please set the section',
  'b.region.range.null': 'The value of [{value}] has no the section data',
  'b.region.value.null': 'The section of [{value}] is not setted',
  'm.document.created.tip': 'Are you sure to submit the document?',
  'm.document.updateapprover.tip': 'Are you sure to update the approvers?',
  'm.document.created.save': 'Are you sure to save the document?',
  'm.document.created.snrequired': 'The document no. cannot be blank.',
  'm.document.created.tags': 'The document tags are not completed',
  'm.succress': 'Sucess',
  'm.succress.submit': 'Submit successfully, the page is closing',
  'm.succress.save': 'Save sucessfully,the page is closing',
  'm.document.created.document': 'Please upload the document',
  'm.document.created.notifypersonnel': 'The notify user is required',
  'm.document.map.isostandard.title': 'ISO standards',
  'm.document.map.isostandard.content':
    'The ISO standard data is not saved,are you sure to set other ISO standard?',
  'm.document.map.Department.save': 'Library Map-Department',
  'm.document.map.Department.save.content':
    'Are you sure to save Library Map-Department setting?',
  'm.document.map.isostandard.save': 'Library Map-ISO Standard',
  'm.document.map.isostandard.save.content':
    'Are you sure to save Library Map-ISO standard [{value}]setting?',
  'e.copy.documents':
    'The document will be locked after revising , are you sure to revise the document?',
};
export default messages;
