import http from './http';

export function login(data) {
  return http.post('/Login/Login', data);
}

export function sSOLogin(data) {
  return http.post('/Login/SSOLogin', data);
}

export function logout() {
  return http.get('/Login/LogOut');
}
