const messages = {
  'e.get': 'Fail to get {name}',
  'e.getlist': 'Fail to get {name} list',
  'e.valfield': 'Fail to valid {label}',
  'e.exists': '{value} exists',
  'e.notexists': '{value} does not exist',
  'e.document.isediting': 'The document is in editing',
  'e.notauthor':
    'You do not have the operation rights, please check your account.',
  'e.docnotexist': 'The document is not found.',
};
export default messages;
