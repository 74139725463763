const messages = {
  'v.username.required': 'User name cannot be empty',
  'v.password.required': 'Password cannot be empty',
  'v.validcode.required': 'Verified code cannot be empty',
  'v.input.required': 'This field cannot be empty',
  'v.email.required': 'The email cannot be empty',
  'v.select.required': 'This field is required',
  'v.attachment.required': 'The attachment is required',
  'v.input.max9': 'The text cannot be more than 9 characters',
  'v.input.max10': 'The text cannot be more than 10 characters',
  'v.input.max16': 'The text cannot be more than 16 characters',
  'v.input.max17': 'The text cannot be more than 17 characters',
  'v.input.max18': 'The text cannot be more than 18 characters',
  'v.input.max20': 'The text cannot be more than 20 characters',
  'v.input.max25': 'The text cannot be more than 25 characters',
  'v.input.max35': 'The text cannot be more than 35 characters',
  'v.input.max40': 'The text cannot be more than 40 characters',
  'v.input.max50': 'The text cannot be more than 50 characters',
  'v.input.max100': 'The text cannot be more than 100 characters',
  'v.input.max200': 'The text cannot be more than 200 characters',
  'v.input.max250': 'The text cannot be more than 250 characters',
  'v.input.max500': 'The text cannot be more than 500 characters',
  'v.input.max2000': 'The text cannot be more than 2000 characters',
  'v.required': '%s is required',
  'v.required.field': '{field} is required',
  'v.checkfield': 'Please check {field}',
};
export default messages;
