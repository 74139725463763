const messages = {
  'v.username.required': '用户名不能为空',
  'v.password.required': '密码不能为空',
  'v.validcode.required': '验证码不能为空',
  'v.input.required': '输入值不能为空',
  'v.email.required': '邮箱不能为空',
  'v.select.required': '选择值不能为空',
  'v.attachment.required': '附件不能为空',
  'v.input.max9': '文本不能超过9个字符',
  'v.input.max10': '文本不能超过10个字符',
  'v.input.max16': '文本不能超过16个字符',
  'v.input.max17': '文本不能超过17个字符',
  'v.input.max18': '文本不能超过18个字符',
  'v.input.max20': '文本不能超过20个字符',
  'v.input.max25': '文本不能超过25个字符',
  'v.input.max35': '文本不能超过35个字符',
  'v.input.max40': '文本不能超过40个字符',
  'v.input.max50': '文本不能超过50个字符',
  'v.input.max100': '文本不能超过100个字符',
  'v.input.max200': '文本不能超过200个字符',
  'v.input.max250': '文本不能超过250个字符',
  'v.input.max500': '文本不能超过500个字符',
  'v.input.max2000': '文本不能超过2000个字符',
  'v.required': '%s不能为空',
  'v.required.field': '{field}不能为空',
  'v.checkfield': '请检查{field}',
};
export default messages;
