const messages = {
  'b.login': 'Login',
  'b.abbemaillogin': 'ABB Email',
  'b.search': 'Search',
  'b.save': 'Save',
  'b.submit': 'Submit',
  'b.cancel': 'Cancel',
  'b.upload': 'Upload',
  'b.uploadpictures': 'Upload Pictures',
  'b.additem': 'Add',
  'b.moveto': 'Move to',
  'b.addto': 'Add to',
  'b.add': 'New',
  'b.edit': 'Edit',
  'b.delete': 'Delete',
  'b.restoredocument': 'Restore Document',
  'b.yes': 'Yes',
  'b.no': 'No',
  'b.exportall': 'Export All',
  'b.export': 'Export',
  'b.import': 'Import',
  'b.detail': 'Details',
  'b.workflowchart': 'Workflow Chart',
  'b.approve': 'Approve',
  'b.confirmpublish': 'Comfirm Publish',
  'b.publish': 'Publish',
  'b.reject': 'Reject',
  'b.return': 'Return',
  'b.approval': 'Approval',
  'b.confirm': 'Confirm',
  'b.determine': 'Yes',
  'b.check': 'Check',
  'b.sparrow': 'Sparrow',
  'b.download': 'Download',
  'b.more': 'More',
  'b.reset': 'Reset',
  'b.back': 'Back',
  'b.collapse': 'Collapse',
  'b.expand': 'Expand',
  'b.preview': 'Preview',
  'b.historyversion': 'History Versions',
  'b.leavemessage': 'Comments',
  'b.openstructuretree': 'View Document Hierarchy',
  'b.share': 'Share',
  'b.collect': 'Favorite',
  'b.cancelfavorite': 'Cancel Favorite',
  'b.favoritecategory': 'Favorites',
  'b.favoritemanage': 'Manage Favorites',
  'b.publication': 'Post',
  'b.newannouncement': 'New Announcement',
  'b.editannouncement': 'Edit Announcement',
  'b.downloadfile': 'Download Document',
  'b.deletefile': 'Delete Document',
  'b.documentcopy': 'Revise',
};
export default messages;
