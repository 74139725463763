import docCookies from '@/utils/cookie';

const Instance_KEY = 'Current-InstanceCode';

export function getInstance() {
  let instance =
    window.localStorage?.getItem(Instance_KEY) ||
    docCookies.getItem(Instance_KEY);
  if (!instance) {
    instance = 'L001';
  }
  return instance;
}

export function setInstance(instance) {
  window.localStorage?.setItem(Instance_KEY, instance);
  const endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 1);
  docCookies.setItem(Instance_KEY, instance, endDate, '/');
  window.location.reload();
}

export function getInstances() {
  return [{ key: 'L001', value: 'TDMS' }];
}
