const messages = {
  'ph.username': '用户名',
  'ph.password': '密码',
  'ph.validcode': '验证码',
  'ph.pleseSelect': '请选择...',
  'ph.pleseInput': '请输入...',
  'ph.pleseInputEmail': '请输入邮箱...',
  'ph.issubmint': '是否确认提交',
  'ph.issave': '是否保存',
  'ph.pleseInputRemark': '请输入备注',
  'ph.document.edit': '确定是否进入编辑文档?',
};
export default messages;
