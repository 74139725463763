import React, { useState, useEffect } from 'react';
import {
  Menu,
  Divider,
  Layout,
  notification,
  Dropdown,
  Row,
  MenuProps,
} from 'antd';
import { UserOutlined, ExportOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectTopMenus,
  selectMenus,
  selectUserType,
  selectUserEmail,
  selectName,
  signOut,
} from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import './TransverseLayoutDefault.less';
import * as LoginService from '@/api/login';
import { HomeOutlined } from '@ant-design/icons';
import eventBus from '@/utils/eventBus';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError } from '@/utils/handleError';
import LanguageSetting from '../language/LanguageSetting';
import Announcement from '../announcement/Announcement';

import CreateDocuments from '@/assets/icons/CreateDocuments.png';
import DocumentMap from '@/assets/icons/DocumentMap.png';
import DocumentStructureTree from '@/assets/icons/DocumentStructureTree.png';
import DocumentRetrieval from '@/assets/icons/DocumentRetrieval.png';
import documentdashboard from '@/assets/icons/documentdashboard.png';
import getsupport from '@/assets/icons/getsupport.png';
import myrelated from '@/assets/icons/myrelated.png';
import systemmanagement from '@/assets/icons/systemmanagement.png';
import {
  CreateDocumentsIcon,
  DocumentRetrievalIcon,
  DocumentStructureTreeIcon,
  DocumentdashboardIcon,
  GetsupportIcon,
  MyrelatedIcon,
  SystemmanagementIcon,
  DocumentMapIcon,
  CollectionIcon,
} from '@/utils/customIcon.js';
import { useHistory } from 'react-router-dom';
import { getLocale } from '@/locales';
function TransverseLayoutDefault({ noWrapper, noFooter, ...props }) {
  let year = new Date().getFullYear();
  const [selectTopMenuKey, setSelectedTopMenuKey] = useState([]);
  const [selectedMenuOpenKey, setSelectedMenuOpenKey] = useState([]);
  const { Header, Content, Footer, Sider } = Layout;
  const [pageHeaderTitle, setPageHeaderTitle] = useState('');
  const intl = useIntl();
  const history = useHistory();
  useEffect(() => {
    let selects = [];
    let opens = [
      'sharewithme',
      'mycreated',
      'mytask',
      'myshare',
      'myfavorites',
    ];
    var topMenuCode = localStorage.getItem('topMenuCode');
    if (topMenuCode) {
      selects.push(topMenuCode);
      setSelectedTopMenuKey(selects);
      if (opens.includes(topMenuCode)) {
        setSelectedMenuOpenKey(['myrelated']);
      } else {
        setSelectedMenuOpenKey([]);
      }
    }
    if (!topMenuCode) {
      eventBus.addListener('selectedTopMenu', function (val) {
        selects.push(val);
        setSelectedTopMenuKey(selects);
        if (opens.includes(topMenuCode)) {
          setSelectedMenuOpenKey(['myrelated']);
        } else {
          setSelectedMenuOpenKey([]);
        }
        localStorage.setItem('topMenuCode', val);
      });
    }
    const hrefStr = window.location.href;
    let treeIndex = hrefStr.indexOf('/document/tree');
    let mapIndex = hrefStr.indexOf('/document/map');
    if (treeIndex >= 0) {
      setPageHeaderTitle(intl.formatMessage({ id: 'menu.documenttree' }));
    } else if (mapIndex >= 0) {
      setPageHeaderTitle(intl.formatMessage({ id: 'menu.documentmap' }));
    }
    // if (!leftMenuCode) {
    //   eventBus.addListener('selectedTopMenu', function (val) {
    //     setSelectedTopMenuKey(val);
    //     localStorage.setItem('leftMenuCode', val);
    //   });
    // }
  }, []);

  function handleTopMenuClick(e) {
    setSelectedTopMenuKey(e.key);
    localStorage.setItem('topMenuCode', e.key);
  }
  function onJumpHome() {
    localStorage.setItem('topMenuCode', 'home');
    history.push('/home');
  }
  return (
    <Layout
      style={{
        minHeight: '100%',
        // maxWidth: '1396px',
        // maxWidth: '1396px',
        // overflow: 'auto',
      }}
      {...props}
    >
      <div className="app-header-wrapper">
        <Row
          className={'abbBar'}
          // style={{
          //   height: '32px',
          //   alignContent: 'center',
          // }}
        >
          <div className="logo" onClick={onJumpHome}></div>
          <div className="header-name">
            <span>大电机与发电机质量管理系统</span>
            <br></br>
            <span style={{ fontSize: '12px', fontWeight: '1' }}>
              Quality Management System
            </span>
          </div>
          <div
            style={{ position: 'absolute', right: '0px', marginRight: '48px' }}
          >
            <Toolbar />
          </div>
        </Row>
        <Header style={{ padding: '0px 16px 0px 16px' }}>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {pageHeaderTitle}
          </div>
          <div
            className="headerbar"
            style={{
              float: 'right',
              width: getLocale() === 'en-US' ? '1000px' : '900px',
            }}
          >
            <NavTopMenubar
              selectedKey={selectTopMenuKey}
              selectedOpenKey={selectedMenuOpenKey}
              handleTopMenuClick={handleTopMenuClick}
            />
          </div>
        </Header>
      </div>
      <Content
        style={{
          display: 'flex',
          // maxWidth: '1396px',
          // height: '90vh',
          // overflow: 'auto',
        }}
      >
        {noWrapper ? (
          props.children
        ) : (
          <div
            className="app-main-wrapper"
            style={{ padding: '0px 0px 0px 0px' }}
          >
            <div
              className="app-main-content"
              style={{
                // width: '90vw',
                // maxWidth: '1396px',
                // maxHeight: 'calc(100vh - 160px)',
                WebkitTransition: 'maxWidth .3s',
                transition: 'maxWidth .3s',
                overflow: 'auto',
              }}
            >
              {props.children}
            </div>
          </div>
        )}
      </Content>
      {noFooter ? null : (
        <Footer
          style={{
            textAlign: 'center',
            fontFamily: 'ABBVoice',
            // marginTop: '5px',
            maxHeight: '40px',
            padding: '10px 0px 10px 0px',
          }}
        >
          &copy; {year} ABB
        </Footer>
      )}
    </Layout>
  );
}

export default TransverseLayoutDefault;

function GetIconImg(menuCode) {
  let result = undefined;
  switch (menuCode) {
    case 'home': //首页
      return <HomeOutlined style={{ fontSize: '16px', marginRight: '12px' }} />;
      break;
    case 'documentmap': //文档地图
      return (
        <DocumentMapIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    case 'documenttree': //文档结构树
      return (
        <DocumentStructureTreeIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'documentcreate': //创建文档
      return (
        <CreateDocumentsIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'myrelated': //与我相关
      return (
        <MyrelatedIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    case 'documentquery': //文档搜索
      return (
        <DocumentRetrievalIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'documentdashboard': //文档报表
      return (
        <DocumentdashboardIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'systemmanagement': //系统管理
      return (
        <SystemmanagementIcon
          style={{ fontSize: '20px', marginRight: '12px' }}
        />
      );
      break;
    case 'getsupport': //帮助
      return (
        <GetsupportIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    case 'myfavorites': //收藏夹
      return (
        <CollectionIcon style={{ fontSize: '20px', marginRight: '12px' }} />
      );
      break;
    default:
      break;
  }
  return <></>;
}
/**
 * 头部菜单
 * @param {*} param0
 * @returns
 */
export function NavTopMenubar(props) {
  const menus = useSelector(selectTopMenus);
  const childrenmenus = useSelector(selectMenus);
  const intl = useIntl();
  const handleTopMenuClickOne = e => {
    props.handleTopMenuClick(e);
  };
  function aa(e) {
    console.log('快出来点击事件');
  }
  // function handleTopMenuClickOne(e) {
  //   console.log('横框架菜单点击事件');
  //   props.handleTopMenuClick(e);
  // }
  function navitem(item) {
    let navitem = {
      key: item.menuCode,
      label: (
        <Link to={item.url}>
          <span style={{ color: 'black', fontFamily: 'ABBVoice' }}>
            {/* {item.menuCode === 'home' && (
              <HomeOutlined style={{ fontSize: '16px', marginRight: '14px' }} />
            )} */}
            {GetIconImg(item.menuCode)}
            {/* {GetIconImg(item.menuCode) && (
              <img
                src={GetIconImg(item.menuCode)}
                width={'20px'}
                style={{ fontSize: '16px', marginRight: '10px' }}
              />
            )} */}
            {intl.formatMessage({ id: 'menu.' + item.menuCode })}
          </span>
        </Link>
      ),
    };
    let childrens = childrenmenus.filter(x => x.parentID === item.id);
    let childrennavItems = [];
    for (let i = 0; i < childrens.length; i++) {
      if (childrens[i].menuCode == 'myfavorites') {
        continue;
      }
      let childrennavItem = {
        ...childrens[i],
        key: childrens[i].menuCode,
        label: intl.formatMessage({ id: 'menu.' + childrens[i].menuCode }),
        children: null,
      };
      childrennavItem.label = (
        <Link to={childrennavItem.url} style={{ fontFamily: 'ABBVoice' }}>
          {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
        </Link>
      );
      childrennavItems.push(childrennavItem);
    }
    if (childrennavItems.length > 0) {
      navitem.children = childrennavItems;
    }
    return navitem;
  }
  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (
        item.menuCode == 'systemmanagement' ||
        item.menuCode == 'getsupport' ||
        item.menuCode == 'documentdashboard'
      ) {
        continue;
      }
      if (item.menuCode == 'documentquery') {
        let childrens = childrenmenus.filter(x => x.menuCode === 'myfavorites');
        if (childrens.length > 0) {
          navItems.push(navitem(childrens[0]));
        }
      }
      navItems.push(navitem(item));
    }
    return navItems;
  }
  return (
    <Menu
      className="topmenus"
      onClick={handleTopMenuClickOne}
      mode="horizontal"
      items={getNavItems(menus)}
      // openKeys={props.selectedOpenKey}
      selectedKeys={props.selectedKey}
    />
  );
}

/**
 * 左侧主菜单
 * @param {*} param0
 * @returns
 */
function NavLeftMainMenubar(props) {
  const menus = useSelector(selectTopMenus);
  const childrenmenus = useSelector(selectMenus);
  const intl = useIntl();

  function handleTopMenuClick(e) {
    props.handleTopMenuClick(e);
  }

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: (
          <Link to={item.url}>
            <span style={{ color: 'black' }}>
              {/* {item.menuCode === 'home' && (
                <HomeOutlined
                  style={{ fontSize: '16px', marginRight: '14px' }}
                />
              )} */}
              {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            </span>
          </Link>
        ),
      };
      let childrens = childrenmenus.filter(x => x.parentID === item.id);
      let childrennavItems = [];
      for (let i = 0; i < childrens.length; i++) {
        let childrennavItem = {
          ...childrens[i],
          key: childrens[i].menuCode,
          label: intl.formatMessage({ id: 'menu.' + childrens[i].menuCode }),
          children: null,
        };
        childrennavItem.label = (
          <Link to={childrennavItem.url}>
            {intl.formatMessage({ id: 'menu.' + childrennavItem.menuCode })}
          </Link>
        );
        childrennavItems.push(childrennavItem);
      }
      if (childrennavItems.length > 0) {
        navitem.children = childrennavItems;
      }
      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="topmenus"
      onClick={handleTopMenuClick}
      mode="inline"
      items={getNavItems(menus)}
    />
  );
}

/**
 * 左侧菜单
 * @param {*} param0
 * @returns
 */
function NavLeftMenubar(props) {
  debugger;
  const topmenus = useSelector(selectTopMenus);
  const menus = useSelector(selectMenus);
  const [openMenuKey, setOpenedMenuKey] = useState([]);
  const intl = useIntl();
  let selectedTopMenuID =
    topmenus.length > 0
      ? props.selectTopMenuID || topmenus[0].Id
      : props.selectTopMenuID;
  let defaultOpenMenuKey = props.openLeftMenuKey.split(',');

  function handleLeftMenuClick(e) {
    props.handleLeftMenuClick(e);
  }

  function handleSetBadgeCount() {
    props.handleSetBadgeCount();
  }

  function getNavItems(items) {
    debugger;
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.parentID !== selectedTopMenuID) {
        continue;
      }
      let navitem = {
        key: item.menuCode,
        label: intl.formatMessage({ id: 'menu.' + item.menuCode }),
      };
      if (item.children && item.children.length > 0) {
        navitem.children = [];
        for (let j = 0; j < item.children.length; j++) {
          const x = item.children[j];
          let navcitem = {
            key: x.menuCode,
            label: (
              <Link to={x.url === undefined ? '' : x.url}>
                {intl.formatMessage({ id: 'menu.' + x.menuCode })}
                <span id={x.menuCode} className="badgeCount">
                  0
                </span>
              </Link>
            ),
          };
          if (x.children && x.children.length > 0) {
            navcitem.children = [];
            for (let k = 0; k < x.children.length; k++) {
              const y = x.children[k];
              let navccitem = {
                key: y.menuCode,
                label: (
                  <Link to={y.url === undefined ? '' : y.url}>
                    {intl.formatMessage({ id: 'menu.' + y.menuCode })}
                    <span id={y.menuCode} className="badgeCount">
                      0
                    </span>
                  </Link>
                ),
              };
              navcitem.children.push(navccitem);
            }
          }
          navitem.children.push(navcitem);
        }
      } else {
        navitem.label = (
          <Link to={item.url}>
            {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            <span id={item.menuCode} className="badgeCount">
              0
            </span>
          </Link>
        );
      }
      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="leftmenus"
      mode="inline"
      openKeys={openMenuKey.length > 0 ? openMenuKey : defaultOpenMenuKey}
      selectedKeys={[props.selectedKey]}
      onClick={handleLeftMenuClick}
      onOpenChange={openKeys => {
        setOpenedMenuKey(openKeys);
        handleSetBadgeCount();
      }}
      items={getNavItems(menus)}
    />
  );
}

/**
 * 右侧操作按钮
 * @param {*} props
 * @returns
 */
export function Toolbar(props) {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const userEmail = useSelector(selectUserEmail);
  const name = useSelector(selectName);
  const userItems = [
    {
      label: '修改用户信息',
      key: 'edituser',
    },
  ];
  const handleUserMenuClick = key => {
    if (key === 'edituser') {
      var url = window.ENV_CFG.edituser_link;
      url = url.replace('{0}', userEmail);
      window.open(url, '_blank');
    }
  };

  const handleLogout = () => {
    dispatch(signOut());
    if (userType === 0) {
      window.location.href = window.ENV_CFG.signout;
    } else {
      LoginService.logout()
        .then(res => {
          if (res.tag === 1) {
            window.location.reload();
          } else {
            notification.error({
              message: res.message,
              placement: 'top',
              duration: 4,
            });
          }
        })
        .catch(e => {
          handleError(e);
        });
    }
  };

  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <Announcement className="lang" />
      </div> */}
      <div className="control-item">
        <LanguageSetting className="lang" />
      </div>
      {name && name.length > 0 && (
        <React.Fragment>
          {/* <div className="control-item">
            <UserOutlined /> {name}
          </div> */}
          {userType === 2 && (
            <div className="control-item">
              <Dropdown
                overlay={
                  <Menu
                    items={userItems}
                    onClick={({ key, domEvent }) => {
                      domEvent.stopPropagation();
                      handleUserMenuClick(key);
                    }}
                  ></Menu>
                }
              >
                <div>
                  <UserOutlined /> {name}
                </div>
              </Dropdown>
            </div>
          )}
          {userType !== 2 && (
            <div className="control-item">
              <UserOutlined /> {name}
            </div>
          )}
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
