const messages = {
  'm.upload.tips':
    '支持JPG、PNG、BMP等图片格式，MP4、MPEG等视频格式，及PDF、Word文档等文件，单个文件不超过50M，最多上传10个附件',
  'm.delete.succress': '删除成功',
  'm.delete.documents': '是否删除选中文档？',
  'm.delete.favorite': '是否删除选中收藏夹？',
  'm.delete.Message': '是否删除选中评论？',
  'm.cancelfavorite.succress': '取消收藏成功',
  'm.cancelfavorite.documents': '是否取消收藏选中文档？',
  'm.share.succress': '分享成功',
  'm.share.documents': '是否分享选中文档？',
  'm.favorite.succress': '收藏成功',
  'm.favorite.documents': '是否收藏选中文档？',
  'm.restore.documents': '是否恢复选中文档？',
  'm.restore.succress': '恢复成功',
  'm.delete.leavemessage': '是否删除选中文档留言？',
  'm.delete.announcement': '是否删除选公告？',
  'e.editing.documents': '文档升版，文档将会被锁定，是否继续操作?',
  'p.delete.nopermissions': '无权限',
  'p.delete.approval': '文档不是草稿状态不能删除',
  'b.uploadpictures.error': '请上传图片',
  'm.move.succress': '成功',
  'm.move.documents': '是否移动选中文档？',
  'm.add.documents': '是否为选中文档添加至新节点？？',
  'b.region.error': '请设置区域',
  'b.region.range.null': '值为[{value}]未设置区域范围的数据',
  'b.region.value.null': '[{value}]号区域未设置值',
  'm.document.created.tip': '确定提交文档审批吗？',
  'm.document.updateapprover.tip': '确定提交审批人修改吗？',
  'm.document.created.save': '确定保存文档吗？',
  'm.document.created.snrequired': '文档编号相关字段未完善',
  'm.document.created.tags': '文档标签相关字段未完善',
  'm.succress': '成功',
  'm.succress.submit': '提交成功，关闭当前页面',
  'm.succress.save': '保存成功，关闭当前页面',
  'm.succress.subscription': '订阅成功！',
  'm.succress.unsubscription': '取消订阅成功！',
  'm.document.created.document': '未上传文档',
  'm.document.created.notifypersonnel': '未设置通知人员',
  'm.document.map.isostandard.title': 'ISO标准',
  'm.document.map.isostandard.content':
    '当前ISO标准数据未保存，确定切换至其他ISO标准吗？',
  'm.document.map.Department.save': '文档地图-部门',
  'm.document.map.Department.save.content': '确定保存文档地图-部门设置吗？',
  'm.document.map.isostandard.save': '文档地图-ISO标准',
  'm.document.map.isostandard.save.content':
    '确定保存文档地图-ISO标准[{value}]设置吗？',
  'e.copy.documents': '复制文档，文档将会被锁定，是否继续操作?',
};
export default messages;
