import { lazy } from 'react';
//import Side1Menu from '../components/SideMenu';
import LayoutDefault from '../components/layout/LayoutDefault';
import TransverseLayoutDefault from '../components/layout/TransverseLayoutDefault';
// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home',
    component: lazy(() => import('../features/home/HomePage')),
    layout: LayoutDefault,
  },
  {
    //文档地图
    auth: 'documentmap',
    path: '/document/map',
    component: lazy(() => import('../features/document/map/Index')),
    layout: TransverseLayoutDefault,
  },
  {
    //文档列表地图
    auth: 'documentlistmap',
    path: '/documentreport/documentlistmap',
    component: lazy(() => import('../features/document/documentListMap/Index')),
    layout: LayoutDefault,
  },
  {
    //文档地图设置
    auth: 'documentmapsetting',
    path: '/document/mapsetting',
    component: lazy(() => import('../features/document/map/setMap')),
    layout: TransverseLayoutDefault,
  },
  {
    //文档结构树
    auth: 'documenttree',
    path: '/document/tree',
    component: lazy(() => import('../features/document/tree/Index')),
    layout: TransverseLayoutDefault,
  },
  {
    //创建文档
    auth: 'documentcreate',
    path: '/document/create',
    component: lazy(() => import('../features/document/create/Index')),
    layout: LayoutDefault,
  },
  {
    //已发布文档列表
    auth: 'publisheddocument',
    path: '/document/publisheddocument',
    component: lazy(() =>
      import('../features/document/publisheddocument/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //文档检索
    auth: 'documentquery',
    path: '/documentreport/query',
    component: lazy(() => import('../features/document/query/Index')),
    layout: LayoutDefault,
  },
  {
    //我发起的
    auth: 'mycreated',
    path: '/documentreport/mycreated',
    component: lazy(() =>
      import('../features/document/relatedtome/mycreated/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //我的任务
    auth: 'mytask',
    path: '/documentreport/mytask',
    component: lazy(() =>
      import('../features/document/relatedtome/mytask/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //我的收藏
    auth: 'myfavorites',
    path: '/documentreport/myfavorites',
    component: lazy(() =>
      import('../features/document/relatedtome/myfavorites/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //我的分享
    auth: 'myshare',
    path: '/documentreport/myshare',
    component: lazy(() =>
      import('../features/document/relatedtome/myshare/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //分享给我
    auth: 'sharewithme',
    path: '/documentreport/sharewithme',
    component: lazy(() =>
      import('../features/document/relatedtome/sharewithme/Index')
    ),
    layout: LayoutDefault,
  },
  {
    //留言
    auth: 'leavemessage',
    path: '/leavemessage',
    component: lazy(() => import('../features/document/leavemessage/Index')),
    layout: LayoutDefault,
  },
  {
    //公告
    auth: 'announcementmanagement',
    path: '/announcementmanagement',
    component: lazy(() => import('../features/announcement/Index')),
    layout: LayoutDefault,
  },
  {
    //回收站
    auth: 'recyclebin',
    path: '/recyclebin',
    component: lazy(() => import('../features/document/recyclebin/Index')),
    layout: LayoutDefault,
  },
  {
    //文档统计数据
    auth: 'documentdashboard',
    path: '/documentreport/dashboard',
    component: lazy(() => import('../features/document/dashboard/Index')),
    layout: LayoutDefault,
  },
  {
    //文档统计数据
    auth: 'accessdetails',
    path: '/documentreport/accessdetails/:year',
    component: lazy(() =>
      import('../features/document/dashboard/accessDetails')
    ),
    layout: LayoutDefault,
  },
  {
    //文档统计数据
    auth: 'downloaddetails',
    path: '/documentreport/downloaddetails/:year',
    component: lazy(() =>
      import('../features/document/dashboard/downloadDetails')
    ),
    layout: LayoutDefault,
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/singlesignin',
    component: lazy(() => import('../features/auth/SingleSignIn')),
    anonymous: true,
    layout: false,
  },
  {
    auth: 'emailtemplatesetting',
    path: '/emailtemplate',
    component: lazy(() => import('../features/emailtemplate/index')),
    layout: LayoutDefault,
  },
  {
    auth: 'tagmanagement',
    path: '/tagmanagement',
    component: lazy(() => import('../features/tagmanagement/TagList')),
    layout: LayoutDefault,
  },
  {
    auth: 'masterdatamanagement',
    path: '/masterdatamanagement',
    component: lazy(() => import('../features/masterData/Index')),
    layout: LayoutDefault,
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/attachment/download/:id',
    component: lazy(() => import('../features/attachment/download')),
    layout: false,
  },
  {
    //草稿
    auth: 'documentdraftpages',
    path: '/document/draft/:id',
    component: lazy(() => import('../features/document/draft/Index')),
    layout: LayoutDefault,
  },
  {
    //明细
    auth: 'documentdetailpages',
    path: '/document/detail/:id',
    component: lazy(() => import('../features/document/detail/DocumentDetail')),
    layout: LayoutDefault,
  },
  {
    //发布明细
    auth: 'documentreleasedetailpages',
    path: '/document/releasedetail/:id',
    component: lazy(() =>
      import('../features/document/detail/ReleaseDocumentDetail')
    ),
    layout: LayoutDefault,
  },
  {
    //升版
    auth: 'documentupdatepages',
    path: '/document/update/:id',
    component: lazy(() => import('../features/document/update/Index')),
    layout: LayoutDefault,
  },
  {
    //审批页面
    auth: 'documentapprovalpages',
    path: '/document/approval/:id',
    component: lazy(() =>
      import('../features/document/detail/DocumentApproval')
    ),
    layout: LayoutDefault,
  },
  {
    // 修改审批人
    auth: 'processmanagement',
    path: '/document/pending',
    component: lazy(() =>
      import('../features/document/updateapprover/DocumentList')
    ),
    layout: LayoutDefault,
  },
  {
    // 修改审批人auth
    auth: 'adjustapproversetting',
    path: '/document/updateapprover/:id',
    component: lazy(() =>
      import('../features/document/updateapprover/UpdateApprover')
    ),
    layout: LayoutDefault,
  },
  {
    auth: 'approveddocument',
    path: '/dataclassification/approval/:id',
    component: lazy(() =>
      import('../features/document/detail/DataClassificationApproval')
    ),
    layout: LayoutDefault,
  },
  {
    //空白页
    auth: 'empty',
    path: '/empty',
    component: lazy(() => import('../features/empty/Index')),
    layout: LayoutDefault,
  },
  {
    //公告栏全部
    auth: 'bulletinboard',
    path: '/bulletinboard',
    component: lazy(() => import('../features/announcement/bulletinBoard')),
    layout: LayoutDefault,
  },
  {
    //文档动态全部
    auth: 'documentdynamics',
    path: '/documentdynamics',
    component: lazy(() =>
      import('../features/document/documentdynamics/Index')
    ),
    layout: LayoutDefault,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
